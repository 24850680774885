import { template as template_decc964cfa414ccb8f841382e24aea5b } from "@ember/template-compiler";
const FKControlMenuContainer = template_decc964cfa414ccb8f841382e24aea5b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
