import { template as template_182a864e811e48c9b2dbabb20f99e2db } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_182a864e811e48c9b2dbabb20f99e2db(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
