import { template as template_34d2ced7d1874c3ba01cdb6f5cea12b3 } from "@ember/template-compiler";
const FKText = template_34d2ced7d1874c3ba01cdb6f5cea12b3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
