import { template as template_59b338d9959e4855a1c4d77410b30122 } from "@ember/template-compiler";
const WelcomeHeader = template_59b338d9959e4855a1c4d77410b30122(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
